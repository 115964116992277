<template>
	<v-main>
		<v-card class="rounded-xl pa-6 text-center login-page" elevation="0" outlined v-if="!sent">
			<h1>Wachtwoord vergeten?</h1>
			<p>Ontvang een nieuwe wachtwoord in uw inbox door uw emailadres in te vullen.</p>
			<v-text-field class="theme-input" solo placeholder="Email" v-model="email" background-color="#f1f8fa"
				title="Email"></v-text-field>
			<p style="color: var(--themered);">{{ error }}</p>
			<v-row>
				<v-col cols="0" md="4"></v-col>
				<v-col cols="12" md="2"><v-btn class="mx-auto d-block btn" color="themewhite"
						to="/inloggen">Terug</v-btn></v-col>
				<v-col cols="12" md="2"> <v-btn class="text-white btn" @click="submit()" color="themepurple"> Verzenden</v-btn></v-col>
			</v-row>
		</v-card>
		<v-card class="rounded-xl pa-6 text-center login-page" elevation="0" outlined v-else>
			<h1>Wachtwoord vergeten?</h1>
			<p>Wachtwoordaanvraag ontvangen, controleer je inbox en spam voor de e-mail.</p>
		</v-card>
	</v-main>
</template>
<script>
import api from '../../services/api';
export default {
	data() {
		return {
			rules: [
				(value) => !!value || "Verplicht",
				(v) => v.length >= 8 || "Ten minste 8 karakters",
			],
			email: "",
			valid: false,
			sent: false,
			error: ""
		};
	},
	methods: {
		submit() {
			api.post(`/v1/auth/forgot`, { email: this.email }).then((response) => {
				console.log(response);
				this.sent = true;
			}).catch(() => {
				this.error = "Dit emailadres bestaat niet.";
			});
		},
	},
};
</script>